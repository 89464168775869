import React from 'react';

// OTHER COMPONENTS
import { Breathe } from 'ui/molecules/AssessmentNext/intermissions/Breathe';

// UTILS
import * as api from 'api';

import { randomizeArray } from 'utils/randomize';

import {
  hasValidStorageValidityWindow,
  storageController,
  copyAssessmentConfig,
} from 'ui/molecules/AssessmentNext/AssessmentNext.logic';
import {
  ASSESSMENT_TYPES,
  NINE_LEVELS_ORDER,
  BREATHE_AT_DECIMAL_FRACTION,
} from 'utils/configuration/const/assessment-types';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';
import { eventBus } from 'architecture/eventBus';
import { translate } from './translator/translator';
import { sortAlphabetically } from './strings';


export const getMappedNineLevelsResults = (assessmentResults = []) => {
  const mappedResults = [];
  const signatureValues = [];

  assessmentResults.forEach((result) => {
    // replace cyan with turquoise. 9levels returns cyan whereas on front-end 'turquoise' is used
    const assessmentResultId = result.id.replace('cyan', 'turquoise');
    const assessmentResultName = result.name.replace('cyan', 'turquoise');

    const obj = {
      id: assessmentResultId,
      name: assessmentResultName,
      hidden: result.hidden,
    };

    if (!result.id.includes('levels_')) {
      return;
    }
    if (result.id.includes('highvalues')) {
      signatureValues.push(result);
      return;
    }
    if (result.id.includes('res')) {
      return;
    }
    if (Array.isArray(result.result)) {
      mappedResults.push(result);
      return;
    }

    const positiveResult = result.result;
    const negativeResult = assessmentResults.find((r) => r.id === result.id.split('_').join('_res_')).result;

    obj.result = [ -Math.abs(Math.ceil(negativeResult)), Math.ceil(positiveResult) ];

    mappedResults[NINE_LEVELS_ORDER[assessmentResultId.split('levels_')[1].toLowerCase()]] = obj;
  });

  return [ mappedResults, signatureValues ];
};

export const mapQuestions = (data, replacements = {}, assessment = {}) => {
  const {
    questions = [],
    configs = [],
    default: defaultConfig = {},
  } = data;

  const getAnswersOptions = (question) => {
    const getLabelLight = (n) => (n <= 10 ? String(n % 10) : null);
    const mapFromQuestionConfig = (config, index) => ({
      id: `answer_option${index + 1}`,
      labelLight: getLabelLight(index + 1),
      label: `${config.answer_option_prefix}${index + config.answer_from}`,
      value: index + config.answer_from,
    });
    const mapFromDefaultConfig = (option, index) => ({
      id: `answer_option${index + 1}`,
      labelLight: getLabelLight(index + 1),
      label: option.text,
      value: option.option,
    });
    const mapFromOptions = (option, index) => ({
      id: option.id,
      labelLight: getLabelLight(index + 1),
      label: option.label,
      value: option.value,
    });

    // highest priority are settings directly defined in the question object (inside questions)
    if (question.answers) {
      return question.answers.map(mapFromOptions);
    }

    // if the question has a config reference, use it to build the options;
    // either the answer options or the prefix can be used, depending on what's available
    const questionConfig = configs.find((config) => config.id === question.config);
    if (questionConfig) {
      if (questionConfig.answer_options?.length > 0) {
        return questionConfig.answer_options.map(mapFromOptions);
      }
      // TODO: get rid of ignoredPrefixes list, see discussion: https://blueexcellence.atlassian.net/issues/BQDQ-1012
      const ignoredPrefixes = [ 'default', 'big5_ass_scale_item' ];
      const prefix = questionConfig.answer_option_prefix;
      if (prefix && !ignoredPrefixes.includes(prefix)) {
        const questionsAmount = questionConfig.answer_to - questionConfig.answer_from + 1;
        return new Array(questionsAmount).fill(questionConfig).map(mapFromQuestionConfig);
      }
    }

    // if neither is defined, the default config is applied
    if (defaultConfig.answerOptions?.length > 0) {
      return defaultConfig.answerOptions.map(mapFromDefaultConfig);
    }

    // question may already have answerOptions from earlier: return them if all the rest failed
    if (question.answerOptions) {
      return question.answerOptions;
    }

    // return nothing for explanatory pages and questions with no options
    return null;
  };

  return questions.map((question) => {
    // Map answer options
    const answerOptions = getAnswersOptions(question);

    // Introduce skip options label
    const skipAnswerOption = answerOptions?.find(({ id }) => id === 'skipAnswerOption');
    if (skipAnswerOption && question.skip_option_label) {
      skipAnswerOption.label = question.skip_option_label;
    }

    // Replace all question tags on all question attributes
    Object.entries(replacements).forEach(([ replacementKey, replacementFn ]) => {
      Object.keys(question).forEach((key) => {
        if (typeof question[key] === 'string') {
          // eslint-disable-next-line no-param-reassign
          question[key] = question[key].replaceAll(`{{${replacementKey}}}`, replacementFn(assessment));
        }
      });
    });

    return {
      ...question,
      answerOptions,
      useAnswerOptions: Boolean(answerOptions),
      isIntermission: question.explanatory,
      question: question.label ? question.label : question.question,
      questionLabel: question.label ? question.question : '',
      questionText: question.question,
      questionPureLabel: question.label,
      questionDescription: question.description,
    };
  });
};

// map answer from onAnswer callback to backend answer
const mapAnswerFrontToBack = (answer, overrideAnswer) => {
  const questionAnswer = {
    question: answer.questionId,
  };

  // disc assessment has a different payload structure for saving the answer
  // so the basic answer structure should be overwritten here
  if (overrideAnswer) {
    Object.assign(questionAnswer, answer.answer);
  } else {
    Object.assign(questionAnswer, { content: answer.answer });
  }

  return questionAnswer;
};

// map answers from backend to frontend
const mapAnswersBackToFront = (answers, questions = []) => answers.map((answer) => {
  let { content } = answer;

  // DISC case: rebuild answer content from questions
  if (!content && answer.most) {
    const question = questions?.find(({ id }) => id === answer.question);
    if (question) {
      const best = question.answerOptions.find(({ option }) => option === answer.most);
      const least = question.answerOptions.find(({ option }) => option === answer.least);
      content = { best, least };
    }
  }

  return {
    questionId: answer.question,
    answer: content,
  };
});

// Insert breathe screen
const insertBreathe = (questions, fraction = BREATHE_AT_DECIMAL_FRACTION) => {
  questions = [ ...questions ]; // FIXME: make function pure
  const index = Math.round((questions.length - 1) * fraction);
  questions.splice(index, 0, {
    isIntermission: true,
    hideOnBackNavigation: true,
    insertAtIndex: index,
    render: (next) => <Breathe onSkip={next} />,
  });

  return questions;
};

// Sort if previous answers are available
const sortByPrevAnswers = (questions = [], prevAnswers = []) => {
  questions = [ ...questions ]; // FIXME: make function pure
  // reverse to put questions at beginning in right order
  prevAnswers = [ ...prevAnswers ].reverse(); // FIXME: make function pure

  if (prevAnswers.length) {
    prevAnswers.forEach((prevAnswer) => {
      const questionIndex = questions.findIndex((question) => question.id === prevAnswer.questionId);
      if (questionIndex === -1) {
        return;
      }
      const question = questions[questionIndex];
      // delete question
      questions.splice(questionIndex, 1);
      // add at beginning
      questions.unshift(question);
    });
  }

  // Sort intermissions
  questions.forEach((q, index) => {
    if (q.insertAtIndex) {
      questions.splice(index, 1);
      questions.splice(q.insertAtIndex, 0, q);
    }
  });

  return questions;
};

const getErrorMessage = (errorObj) => {
  const error = errorObj?.error || {};
  const { errorCode, errorMessage } = error;
  if (errorCode === 1420) {
    return translate('peer_assmnt_missing_required_answer_msg');
  }

  return errorMessage || errorObj || 'network_error';
};

export const handleAssessmentAnswer = (answer, assessmentId, returnToUrl = false, overrideAnswer = false) => {
  const stageNumber = answer.stageNumber || 1;
  // console.log('answer', answer);
  if (answer && answer.questionId && answer.answer !== undefined) {
    eventBus.dispatch('assessmentNext.sendingAnswer', {
      answerId: answer.questionId,
      sending: true,
    });

    return api.post(`/core/assessments/${assessmentId}/${stageNumber}/answers`, {
      answers: [ mapAnswerFrontToBack(answer, overrideAnswer) ],
    })
    .then((response) => {
      if (!response.ok) {
        console.error(response.data);
        eventBus.dispatch('assessmentNext.error', {
          message: getErrorMessage(response.data),
          returnToUrl,
        });
        return undefined;
      }

      return response.data;
    })
    .catch((error) => {
      eventBus.dispatch('assessmentNext.error', {
        message: error.toString?.() || 'network_error',
        returnToUrl,
      });
    })
    .finally(() => {
      eventBus.dispatch('assessmentNext.sendingAnswer', {
        answerId: answer.questionId,
        sending: false,
      });
    });
  }
  return api.get('core/user/heartbeat');
};

// call cancel before getting assessment if we aren't in a valid storage state window
export const assessmentShouldCancel = (assessmentType, userId, shareToken) => {
  // call cancel before getting assessment if we aren't in a valid storage state window
  const config = copyAssessmentConfig(assessmentType);
  storageController.init(assessmentType, userId, shareToken);
  const deflatedStorageState = storageController.loadState();
  storageController.reset();

  if (!deflatedStorageState) {
    return false;
  }

  return !hasValidStorageValidityWindow(deflatedStorageState, config);
};

/*
 * ASSESSMENT CONFIG INIT
 */
export const initialiseAssessment = async (params) => {
  const {
    userId,
    assessmentId,
    replacements,
    handleExit,
    shareToken,
  } = params;

  if (!userId || !assessmentId || !handleExit) {
    throw new Error(`invalid userId, assessmentId or handleExit: ${userId}, ${assessmentId}, ${handleExit}`);
  }

  let assessmentType = ASSESSMENT_NEXT_TYPES[assessmentId]
    || String(assessmentId); // fallback for custom assessments

  // Override BY type locally to retrieve cached answers and prevent canceling the assessment
  if (assessmentId === ASSESSMENT_TYPES.BALANCED_YOU) {
    assessmentType = 'balancedYou';
  }

  // Call cancel before getting assessment if we aren't in a valid storage state window
  const shouldCancel = assessmentShouldCancel(assessmentType, userId, shareToken);
  if (shouldCancel) {
    try {
      await api.post(`core/assessments/${assessmentId}/cancel`);
    } catch ({ message }) {
      console.error(message);
    }
  }

  try {
    // Fetch assessment info, questions, and answers
    const [
      { status: assessmentStatus, data: assessmentData = {} },
      { status: questionsStatus, data: questionsData = {} },
      { status: answersStatus, data: answersData = {} },
    ] = await Promise.all([
      api.get(`/core/assessments/${assessmentId}`),
      api.get(`/core/assessments/${assessmentId}/1/questions`),
      api.get(`/core/assessments/${assessmentId}/1/answers`),
    ]);

    // Process assessment (check exit conditions)
    let assessment;
    if (assessmentStatus === 404 || (assessmentStatus === 403 && assessmentData.error?.errorCode === 1105)) {
      handleExit(assessmentData);
    }
    if (assessmentStatus === 200) {
      if (assessmentData.locked) {
        handleExit(assessmentData);
      }
      assessment = assessmentData;
    }

    // Process questions
    let questions;
    if (questionsStatus === 200) {
      questions = mapQuestions(questionsData, replacements, assessment);
      if (assessment?.randomized) {
        questions = randomizeArray(questions); // randomize questions order
      }
      if (assessment?.interstitial) {
        questions = insertBreathe(questions); // insert Breathe at 80%
      }
    }

    // Process previous answers
    let prevAnswers;
    if (answersStatus === 200) {
      prevAnswers = mapAnswersBackToFront(answersData.answers, questions);
      if (assessment?.randomized && prevAnswers?.length) {
        questions = sortByPrevAnswers(questions, prevAnswers);
      }
    }

    // Return all processed data
    return { questions, assessment, prevAnswers };
  } catch ({ message }) {
    console.error(message);
  }

  return {};
};


export const orderProfileAssessments = (assessments) => {
  const unorderedCoreAssessments = [];
  const orderedCoreAssessments = [];
  const customAssessments = [];

  // separate core and custom assessments into 2 different arrays
  assessments.forEach((assessmentItem) => {
    if (Object.values(ASSESSMENT_TYPES).includes(assessmentItem.assessment)) {
      unorderedCoreAssessments.push(assessmentItem);
    } else {
      customAssessments.push(assessmentItem);
    }
  });

  // order core assessments
  Object.values(ASSESSMENT_TYPES).forEach((assessmentId) => {
    const thisAssessment = unorderedCoreAssessments.find((a) => a.assessment === assessmentId);
    if (thisAssessment) {
      orderedCoreAssessments.push(thisAssessment);
    }
  });

  return orderedCoreAssessments.concat(customAssessments);
};

// Right after fetching an assessment, add some extra params to its payload.
export const preprocessAssessment = (assessment = {}) => {
  const res = JSON.parse(JSON.stringify(assessment));

  res.resultSchemaLanguage = localStorage.getItem('selectedLanguage');
  res.renderReferenceInput = res.render_reference_input
  ?? res.renderReferenceInput
  ?? 'stepped-scale';
  res.renderReferenceMin = res.evaluation?.normalize_to?.min
  ?? res.evaluation?.[0]?.normalize_to?.min
  ?? res.renderReferenceMin
  ?? 1;
  res.renderReferenceMax = res.evaluation?.normalize_to?.max
  ?? res.evaluation?.[0]?.normalize_to?.max
  ?? res.renderReferenceMax
  ?? 5;

  return res;
};

// Take peerResults object and turn it into an array for results display
export const replace360PeerResults = (assessment = {}, assessmentResults = []) => {
  const sort = (itemA, itemB) => {
    if (!assessment.customReport) {
      return 0; // only sort assessments with custom report
    }
    if (itemA.id === 'self') {
      return -1; // 'self' always on top
    }
    return sortAlphabetically(itemA.id, itemB.id);
  };
  const mapDimension = (dim, dimSchema = {}) => ({
    ...dim,
    name: dim.name ?? dimSchema.name,
    description: dim.description ?? dimSchema.description,
    minResult: dim.minResult ?? dim.range?.[0],
    maxResult: dim.maxResult ?? dim.range?.[1],
    peerResults: Object.entries(dim.peerResults ?? {})
    .map(([ key, peerResult ]) => ({
      id: key,
      name: assessment.peerGroups?.[key]?.label,
      ...peerResult,
    }))
    .sort(sort),
  });
  const getDimensionSchema = (dimensionId, schema = []) => schema.find(({ id }) => id === dimensionId);

  return assessmentResults
  .filter((dimension) => !dimension.parentDimension)
  .map((dimension = {}) => {
    const dimensionSchema = getDimensionSchema(dimension.id, assessment.resultSchema);
    return {
      ...mapDimension(dimension, dimensionSchema),
      subDimensions: assessmentResults
      .filter((subDimension) => subDimension.parentDimension === dimension.id)
      .map((subDimension = {}) => {
        const subDimensionSchema = getDimensionSchema(subDimension.id, dimensionSchema?.subDimensions);
        return mapDimension(subDimension, subDimensionSchema);
      })
      .sort(sort),
    };
  })
  .sort(sort);
};

export const filterHiddenResults = (results) => results?.filter((result) => {
  if (result.hidden) {
    return false;
  }

  if (result.parentDimension) {
    const thisParent = results?.find((r) => r.id === result.parentDimension);
    if (thisParent?.hidden) {
      return false;
    }
  }

  return true;
});

export const filterResultSchema = (resultSchema) => (
  resultSchema
  ?.filter((dimension) => !dimension.hidden)
  .map((dimension) => ({
    ...dimension,
    subDimensions: dimension.subDimensions?.filter((subDimension) => (
      !subDimension.hidden
    )),
  }))
);

export const filterHiddenTeamResults = (results) => (
  results
  ?.filter((r) => !r.hidden)
  .map((result) => ({
    ...result,
    subDimensions: result.subDimensions?.filter((r) => !r.hidden),
  }))
);

// Since we need to keep track of the loading state of a PDF report download,
// the call params are used to uniquely identify each download in store.
export const serialiseAssessmentReportPdfParams = (payload = {}) => {
  const {
    id = '',
    resultId = '',
    userId = '',
  } = payload;
  return `${id}_${resultId}_${userId}`;
};
